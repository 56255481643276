import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`AGPL3`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gnu.org/licenses/agpl-3.0.en.html"
        }}>{`https://www.gnu.org/licenses/agpl-3.0.en.html`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tldrlegal.com/license/gnu-affero-general-public-license-v3-(agpl-3.0)"
        }}>{`https://tldrlegal.com/license/gnu-affero-general-public-license-v3-(agpl-3.0)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      